<template>
  <div class="dealDetails">
    <!-- 上面部分 -->
    <div class="detailsTop">
      <div class="goodTop">
        <!-- 左边图片展示 -->
        <div class="detailsleft">
          <img :src="imgUrl" />
          <div class="imgClick">
            <img @click="imgurlClick(item, index)"
                 ref='img'
                 :src="item"
                 alt=""
                 v-for="(item, index) in goodList.product_image_url"
                 :key="index">
          </div>
        </div>
        <!-- 右边详情显示区 -->
        <div class="detailsRight">
          <!-- 产品结束时间 -->
          <div class="goods-expires"
               v-if="!timeBool"
               style="color: red">
            <i class="el-icon-alarm-clock"
               style="color: #5b5b5b"></i>
            This product has been pulled off shelves
          </div>
          <!-- 产品标题 -->
          <h1>{{goodList.product_title}} </h1>
          <!-- 产品国家 -->
          <div class="country-text">
            <div class="countryBox">
              <span :class="goodList.site_class"></span>
              <span class="type"
                    style="margin-left: 10px">{{goodList.category}}</span>
            </div>
            <!-- 评论点赞 -->
            <div class="chooesBox">
              <div class="likeBox">
                <li class="comments">0</li>
                <!-- <span class="number">0</span> -->
              </div>
              <div class="likeBox">
                <li :class="likeBool ? 'hollow' : 'solid'"
                    ref="like"
                    @click="likeDeal()">{{likeNum}}</li>
                <!-- <span class="number">0</span> -->
              </div>
              <div class="likeBox">
                <li class="good">0</li>
                <!-- <span class="number">0</span> -->
              </div>
              <div class="likeBox">
                <li class="bad">0</li>
                <!-- <span class="number">0</span> -->
              </div>
            </div>
          </div>
          <div class="goodBox">
            <div class="goodLeft">
              <!-- 产品价格与折扣 -->
              <div class="goods-price">
                <b>
                  <i v-if="goodList.site === 'US'">$</i>
                  <i v-if="goodList.site === 'JP'">￥</i>
                  <i v-if="goodList.site === 'UK'">￡</i>
                  <i v-if="goodList.site === 'CA' || goodList.site === 'MX' || goodList.site === 'DE' ||goodList.site === 'FR' || goodList.site === 'IT' || goodList.site === 'ES'|| goodList.site === 'NL' ">€</i>
                  {{goodList.deal_price}}
                </b>
                <span>
                  <span v-if="goodList.site === 'US'">$</span>
                  <span v-if="goodList.site === 'JP'">￥</span>
                  <span v-if="goodList.site === 'UK'">￡</span>
                  <span v-if="goodList.site === 'CA' || goodList.site === 'MX' || goodList.site === 'DE' ||goodList.site === 'FR' || goodList.site === 'IT' || goodList.site === 'ES'|| goodList.site === 'NL' ">€</span>
                  {{goodList.sale_price}}
                </span>
              </div>
              <div class="goods-info">
                <!-- 返现金额 -->
                <div>
                  <strong>
                    <i v-if="goodList.site === 'US'">$</i>
                    <i v-if="goodList.site === 'JP'">￥</i>
                    <i v-if="goodList.site === 'UK'">￡</i>
                    <i v-if="goodList.site === 'CA' || goodList.site === 'MX' || goodList.site === 'DE' ||goodList.site === 'FR' || goodList.site === 'IT' || goodList.site === 'ES'|| goodList.site === 'NL' ">€</i>
                    {{cashNum}}
                  </strong>
                  <br />Cashback
                </div>
                <!-- 发货方 -->
                <div>
                  <strong>Fullfilled by</strong>
                  <br />Amazon
                </div>
                <!-- 结束时间 -->
                <div v-if="timeBool">
                  <strong>Only <span style="color: red">{{goodList.remaining_number}}</span> left</strong>
                  <br /><span style="color: red">{{timer}} days</span> expires
                </div>
              </div>
              <!-- 获取优惠卷 -->
              <div v-if="$store.state.tokenBool">
                <div v-if="!timeBool">
                  <el-button class="info"
                             type="info"
                             disabled>Review Request</el-button>
                </div>
                <div v-if="timeBool">
                  <div v-if="!getBool">
                    <el-button v-if="!continueBool"
                             class="coupou"
                             :disabled = 'disabledBool'
                             @click="revSucc(goodList)" :loading="loadingBool">Review Request</el-button>
                  <el-button v-if="continueBool"
                             class="coupou"
                             @click="$router.push('/A_requests')">Continue Request</el-button>
                  </div>
                  <div v-if="getBool">
                    <el-button class="info"
                             type="info"
                             disabled>Review Request</el-button>
                  <div class="coupouText"
                       v-if="timeBool">The modified product has been applied for and cannot be applied for repeatedly</div>
                  </div>
                </div>
                <el-dialog title="提示"
                           :visible.sync="dialogVisible"
                           width="30%">
                  <span>Request success</span>
                  <span slot="footer"
                        class="dialog-footer">
                    <el-button type="primary"
                               @click="revGood()">confirm</el-button>
                  </span>
                </el-dialog>
                <div v-if="pointBool"
                     class="pointText">Buy and Earn {{goodList.reward_integral}} Points!</div>
                <div class="tipsTexts">
                  Crediscounts the buyer's registered account will give 100 points. Every time the buyer applies for a gift, 10 points will be frozen. If it is cancelled or rejected, 10 points will be frozen, deducted directly and will not be returned. When the order is completed, freeze 10 points and return to the points.
                </div>
              </div>
              <div v-if="!$store.state.tokenBool">
                <el-button class="coupou"
                           @click="goLogin()">Login</el-button>
              </div>
              <!-- 分享 -->
              <div class="shareBtn">
                <ul class="center">
                  <li class="facebook">
                    <a href="https://www.facebook.com/" target="_blank">
                      <img src="../../assets/img/facebook.svg"
                           alt />
                    </a>
                  </li>
                  <li class="twitter">
                    <a href="http://www.tuiteblog.com/" target="_blank">
                      <img src="../../assets/img/twitter.svg"
                           alt />
                    </a>
                  </li>
                  <li class="pinterest">
                    <a href="https://www.pinterest.com/search/pins/?q=gofundraise" target="_blank">
                      <img src="../../assets/img/pinterest.svg"
                           alt />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="goodRight">
              <!-- 操作流程 -->
              <div class="dealsBox">
                <div class="set-hd">
                  For "DEALS" products, follow the steps as below
                </div>
                <div class="set-bd">
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_1.png"
                         alt />
                    <span>
                      Click the ‘Review Request’
                      <br />button and Waiting for audit
                    </span>
                  </div>
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_2.png"
                         alt />
                    <span>
                      Get seller confirmation and buy
                      <br />on Amazon within 7 days
                    </span>
                  </div>
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_3.png"
                         alt />
                    <span>
                      Fill in Amazon order info
                      <br />and approved by the seller
                    </span>
                  </div>
                  <div class="set-bd-item">
                    <img src="../../assets/img/process_4.png"
                         alt />
                    <span>
                      Get the refund to your
                      <br />PayPal account
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 这里列举五大卖点 -->
      <div class="fivehot">
        <h1>Product Description</h1>
        <li v-for="(item, index) in fivehotList"
            :key="index">{{item}}</li>
      </div>
    </div>
    <!-- 下面部分 -->
    <div class="detailsBottom">
      <!-- 推荐产品 -->
      <div class="likeModule">
        <!-- 推荐 -->
        <!-- <div class="likeBox">
          <img src="../../assets/img/like.png"
               alt=""
               class="like-icon" />
          <div class="likeTitle">You May Also Like :</div>
        </div> -->
        <!-- 产品展示 -->
        <!-- <div class="imgshows">
          <li class="showbox"
              v-for="(item, index) in likeList"
              :key="index"
              style="background: white">
            <img src="../../assets/img/LED.jpg"
                 alt />
            <div class="title">
              Aircover 1.2-1.5 GALLON Biodegradable Trash...
            </div>
            <div class="fullfilled">Fulfilled by merchant</div>
            <div class="price">
              $17.73
              <span>$28.97</span>
            </div>
            <div class="twobtn">
              <div class="off">40% OFF</div>
              <div class="coupon"
                   @click="$router.push('/F_getConpou')">Review Request</div>
            </div>
          </li>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  getGooddetails, // 获取商品详情
  getGood, // 赠品发起商品请求
  sendMessage, // 获取用户发送信息
  freeLike, // 获取赠品商品收藏信息
  likeGood, // 收藏商品
  unlikeGood // cancle收藏
} from '../../api/buyerFront'
import {
  findAll // 获取请求的赠品
} from '../../api/buyerAfter'
import { getToken } from '../../utils/token'
import { returnFloat } from '../../utils/num'
export default {
  data () {
    return {
      // 弹出层
      dialogVisible: false,
      // 展示图片
      imgUrl: '',
      likeList: [
        { index: 1 },
        { index: 1 },
        { index: 1 },
        { index: 1 },
        { index: 1 }
      ],
      goodList: [],
      // 返还金额
      cashNum: '',
      // 卖点
      fivehotList: [],
      // 结束时间
      timer: '',
      // 积分展示
      pointBool: false,
      // 收藏
      likeBool: true,
      likeNum: 0,
      // 优惠劵按钮
      timeBool: true,
      // 继续申请
      continueBool: false,
      getBool: false,
      disabledBool: false,
      loadingBool: true
    }
  },
  created () {
    // 获取商品详情
    getGooddetails({
      productId: this.$route.query.goodUrl // 商品id
    }).then(result => {
      if (result.data.code === 200) {
        this.loadingBool = false
        this.goodList = result.data.data // 获取商品详情数据
        this.goodList.deal_price = returnFloat(this.goodList.deal_price)
        this.goodList.sale_price = returnFloat(this.goodList.sale_price)
        this.cashNum = returnFloat(parseInt(result.data.data.sale_price - result.data.data.deal_price)) // 计算返还金额
        this.imgUrl = this.goodList.product_image_url[0] // 图片地址
        this.fivehotList = JSON.parse(this.goodList.description) // 卖点数据
        // ('id ==>', this.goodList.id)
        localStorage.setItem('buyer-id', this.goodList.id)
        // 判断是否需要显示积分
        if (this.goodList.reward_integral !== 0) {
          this.pointBool = true
        }
        this.$nextTick(() => {
          // 添加红色边框样式
          this.$refs.img[0].style.border = '1px solid red'
        })
        // 判断商品是否为0
        if (this.goodList.remaining_number === 0) {
          this.disabledBool = true
        }
        const timeNum = result.data.data.end_time - result.data.data.create_date // // 计算时间戳
        this.timer = Math.floor((timeNum / 60 / 60) % 24) // 格式化时间戳
        const goodTime = (new Date()).getTime() // 获取当前时间戳
        // 判断活动时间是否截止
        if (goodTime > this.goodList.end_time) {
          this.timeBool = false
        }
        if (getToken()) {
          // 获取请求的赠品
          findAll({
            productId: this.goodList.id
          }).then(result => {
            if (result.data.code === 200) {
              if (parseInt(result.data.data) === 0) {
                this.continueBool = false
              } else if (parseInt(result.data.data) === 8) {
                this.getBool = true
              } else {
                this.continueBool = true
              }
            }
          }).catch(err => {
            return err
          })
          // 获取赠品收藏商品
          freeLike({
            page: '', // 当前页
            size: '' // 数量
          }).then(result => {
            if (result.data.code === 200) {
              result.data.data.rows.map((v, index) => {
                // 判断赠品是否被收藏
                if (v.product_id === this.$route.query.goodId) {
                  this.likeNum = 1
                  this.likeBool = false
                }
              })
            }
          }).catch(err => {
            return err
          })
        }
      }
      // ('result ==>', this.goodList)
    }).catch(err => {
      return err
    })
  },
  methods: {
    // 添加喜欢
    likeDeal () {
      const a = this.$route.query.goodId // 获取商品id
      // (getToken())
      // 通过开关灯来判断是否收藏
      if (getToken()) {
        if (this.likeBool === true) {
          // 收藏商品
          likeGood(a).then(result => {
            if (result.data.code === 200) {
              this.likeNum = 1
              this.likeBool = false
              this.$message.success('Collected')
            }
          }).catch(err => {
            return err
          })
        } else {
          // cancle收藏
          unlikeGood(a).then(result => {
            if (result.data.code === 200) {
              this.likeNum = 0
              this.likeBool = true
              this.$message('Cancelled')
            }
          }).catch(err => {
            return err
          })
        }
      } else {
        this.$message('Please log in first')
        setTimeout(() => {
          this.$router.push({ path: '/F_login' })
        }, 500)
      }
    },
    // 获取赠品
    revSucc (item) {
      if (item.site === localStorage.getItem('buyer-site')) {
        if (parseInt(item.remaining_number) === 0) {
          this.$message.error('Can"t apply for the same gift')
        } else {
          this.loadingBool = true
          // 赠品发起商品请求
          getGood({ id: this.goodList.id }).then(result => {
            // console.log(result)
            if (result.data.code === 200) {
              this.dialogVisible = true
              this.loadingBool = false
              // 获取用户发送信息
              sendMessage({
                requestId: result.data.data
              }).then(result => {
                if (result.data.code === 200) {
                  // console.log(result)
                  // this.$store.commit('getFreebool', true)
                }
              }).catch(err => {
                return err
              })
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(err => {
            return err
          })
        }
      } else {
        this.$message.error(`You can't test this product, please test the products of ${localStorage.getItem('buyer-site')}`)
      }
    },
    // 跳转后台赠品
    revGood () {
      this.dialogVisible = false
      this.$router.push('/A_requests')
    },
    // 图片选项卡
    imgurlClick (item, index) {
      this.imgUrl = item
      this.$refs.img.map((v, i) => {
        this.$refs.img[i].style.border = '0'
        if (i === index) {
          this.$refs.img[index].style.border = '1px solid red'
        }
      })
      this.$refs.img[index].style.border = '1px solid red'
    },
    // 跳转登录
    goLogin () {
      this.$router.push({ path: '/F_login' })
    }
  }
}
</script>

 <style lang='less' scoped>
.dealDetails {
  background-color: #d8d8d8;
  padding-top: 25px;
  text-align: left;
  .detailsTop {
    background-color: #ffffff;
    // max-width: 1500px;
    // height: 865px;
    padding: 30px 15px;
    .goodTop {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      .detailsleft {
        position: relative;
        padding-right: 15px;
        img {
          display: block;
          width: 450px;
          height: 512px;
        }
        .imgClick {
          padding-left: 20px;
          img {
            margin: 0 5px;
            float: left;
            width: 50px;
            height: 50px;
            cursor: pointer;
          }
        }
        .el-button {
          position: absolute;
          bottom: 0;
          font-size: 14px;
          background: #f93;
          padding: 5px 15px;
          color: white;
        }
      }
      .detailsRight {
        width: 70%;
        padding-left: 15px;
        .goods-expires {
          line-height: 21px;
          font-size: 14px;
          color: #5b5b5b;
          .el-icon-alarm-clock {
            margin-right: 5px;
          }
          span {
            color: #ff0100;
          }
        }
        h1 {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 10px;
        }
        .country-text {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          // position: relative;
          .countryBox {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
            }
          }
          .chooesBox {
            display: flex;
            justify-content: center;
            align-items: center;
            .likeBox {
              display: flex;
              justify-content: center;
              align-items: center;
              li {
                width: 32px;
                height: 32px;
                margin-right: 15px;
                text-align: right;
                line-height: 32px;
                // display: flex;
                // justify-content: flex-end;
                // align-items: center;
                background: url("../../assets/img/icon-interaction-tips.png")
                  no-repeat;
              }
              .solid {
                cursor: pointer;
                background-position: -6px -33px;
              }
              .hollow {
                cursor: pointer;
                background-position: -6px -66px;
              }
              .good {
                background-position: -6px -102px;
              }
              .bad {
                background-position: -6px -134px;
              }
            }
          }
        }
        .goodBox {
          display: flex;
          justify-content: space-between;
          .goodLeft {
            width: 50%;
            .goods-price {
              margin: 10px 0;
              font-size: 48px;
              color: #ff9933;
              font-weight: bolder;
              line-height: 1.3;
              span {
                font-size: 16px;
                text-decoration: line-through;
                color: #b1b1b1;
                font-weight: 400;
              }
            }
            .goods-info {
              width: 70%;
              border-top: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
              display: flex;
              justify-content: flex-start;
              padding: 5px;
              box-sizing: border-box;
              div {
                text-align: center;
                width: 33%;
                font-size: 16px;
                line-height: 32px;
                color: #666;
              }
            }
            .coupou {
              margin-top: 10px;
              width: 70%;
              background: #ff9933;
              color: white;
              font-size: 32px;
              line-height: 60px;
              padding: 0;
            }
            .info {
              margin-top: 10px;
              width: 70%;
              font-size: 32px;
              line-height: 60px;
              padding: 0;
            }
            .coupouText {
              margin: 5px 0;
              width: 68%;
              padding: 5px;
              border: 1px dashed #f93;
              background-color: rgb(255, 220, 184);
              color: black;
              font-size: 14px;
              font-weight: 600;
            }
            .tipsTexts {
              margin-top: 10px;
              width: 70%;
              border: 1px dashed black;
              padding: 5px 5px 20px 5px;
            }
            .pointText {
              margin-top: 10px;
              width: 70%;
              border: 1px dashed #1ab394;
              text-align: center;
              padding: 5px;
            }
            .shareBtn {
              width: 70%;
              margin-top: 10px;
              .center {
                display: flex;
                justify-content: center;
                // background-color: black;
                li {
                  margin: 0 5px;
                  border-radius: 3px;
                  transition: all 0.5s;
                  a {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  &:hover {
                    transform: translateY(-5px);
                    opacity: 0.8;
                  }
                }
                .facebook {
                  background-color: #4267b2;
                }
                .twitter {
                  background-color: #55acee;
                }
                .pinterest {
                  background-color: #cb2027;
                }
                .sharelink {
                  background-color: #95d03a;
                }
              }
            }
          }
          .goodRight {
            margin-top: 10px;
            width: 400px;
            border: 1px solid #cdd4b6;
            height: 100%;
            .dealsBox {
              .set-hd {
                text-align: center;
                font-size: 17px;
                color: white;
                background-color: #f3b24c;
                line-height: 48px;
              }
              .set-bd {
                background-color: #f5ffd7;
                // box-sizing: border-box;
                .set-bd-item {
                  padding: 3px 0 3px 16px;
                  display: flex;
                  justify-content: flex-start;
                  border-top: 1px solid #cdd4b6;
                  img {
                    display: block;
                    height: 60px;
                    width: auto;
                    margin-right: 16px;
                  }
                  span {
                    font-size: 20px;
                    line-height: 1.5;
                    span {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .fivehot {
      margin-top: 20px;
      line-height: 32px;
      font-size: 16px;
      text-align: left;
      h1 {
        font-weight: 100;
        font-size: 24px;
      }
      li {
        padding-left: 10px;
        list-style: disc;
      }
    }
  }
  .detailsBottom {
    padding: 36px 0;
    background-color: #f1f1f1;
    .comment {
      padding: 0 150px;
      display: flex;
      justify-content: flex-start;
      .comment-user {
        width: 10%;
        font-size: 26px;
      }
      .comment-text {
        width: 80%;
        text-align: left;
        h3 {
          margin-bottom: 15px;
          font-size: 20px;
          line-height: 30px;
        }
        .el-button {
          margin-top: 15px;
          margin-bottom: 15px;
          font-size: 16px;
          font-weight: bolder;
          color: white;
          background-color: #ff9933;
        }
      }
    }
    .likeModule {
      margin: 0 auto;
      max-width: 1500px;
      width: 100%;
      .likeBox {
        display: flex;
        align-items: center;
      }
      .like-icon {
        margin-right: 7px;
      }
      .likeTitle {
        display: inline-block;
        font-size: 28px;
        font-weight: bold;
        margin-left: 10px;
        line-height: 1.5;
      }

      .imgshows {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        li {
          width: 17%;
          height: 388px;
          padding: 15px;
          box-sizing: border-box;
          border: 1px solid #ccc;
          border-radius: 20px;
          margin-bottom: 70px;
          cursor: pointer;
          // a {
          img {
            width: 100%;
            height: auto;
            margin: 0 auto;
          }
          .title {
            font-size: 18px;
            line-height: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 10px;
          }
          .fullfilled {
            text-align: left;
            color: #999;
            margin: 10px auto 20px;
          }
          .price {
            font-size: 24px;
            line-height: inherit;
            color: #ff9933;
            font-weight: bolder;
            margin: 10px auto 10px;
            span {
              font-size: 16px;
              font-weight: normal;
              text-decoration: line-through;
            }
          }
          .twobtn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .off {
              padding: 3px 6px;
              background-color: #e9ecef;
              font-size: 12px;
              border-radius: 2px;
              line-height: 24px;
            }
            .coupon {
              font-size: 12px;
              color: #fff;
              background: #ff9933;
              border-radius: 3px;
              padding: 3px 6px;
              font-weight: bolder;
              line-height: 24px;
            }
          }
          // }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-button:active {
  border-color: transparent !important;
}
</style>
